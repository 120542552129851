@use 'style/grid.scss';
@use 'style/color.scss';
@use 'style/util.scss';

.sidebar {
  :global {
    .aside-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: grid.$space-3 grid.$space-3;
    }

    .info {
      margin-top: grid.$space-4;
    }
    
    .social {
      display: flex;
      align-items: center;
      gap: grid.$space-2;
      margin-top: grid.$space-3;
      margin-bottom: grid.$space-4;
    
      a {
        width: 25px;
        height: 25px;
      }
    }

    .page-navigation {
      max-height: 100%;
      display: flex;
      flex-direction: column;
    }

    .sidebar-content {
      max-height: 100%;
    }
  }
}

:global {
  main {
    width: calc(100% - grid.$sidebar-width);
  }

  @include grid.mobile {
    main {
      width: 100%;
    }

    aside {
      display: none;
    }
  }
}