// text
$primary: #2c2c2c;
$secondary: #275AA1;
$third: #fff;
$fourth: #555;

// icons
$icon-primary: $third;

// backgrounds
$bg: #112746;
$bg-secondary: $third;
$bg-third: #eee;
$bg-fourth: #275aa1;
$bg-fifth: #527bb3;
$bg-sixth: #a8bdd9;
$bg-seventh: #58687d;

// borders
$border: $bg-third;
$border-secondary: $third;
$border-third: $secondary;
$border-fourth: $bg-seventh;
