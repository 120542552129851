@use 'style/grid.scss';
@use 'style/color.scss';
@use 'style/util.scss';

.navigation {
  overflow-y: scroll;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0, rgba(color.$bg-seventh, 0)),
      color-stop(0.5, rgba(color.$bg-sixth, 0.5)),
      color-stop(1, rgba(color.$bg-seventh, 0)),
    );
  }

  :global {
    .website-navigation,
    .page-navigation,
    .downloads {
      display: none;
      flex-direction: column;
      gap: grid.$space-0-2;

      &.active {
        display: flex;
      }
    }

    .link {
      text-decoration: none;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: grid.$space-1;
      padding: grid.$space-0-2;
      color: color.$third;
  
      svg {
        max-width: 20px;
      }

      &:hover {
        border-radius: grid.$radius-1;
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    .menu {
      display: flex;
      flex-direction: column;
      padding-bottom: grid.$space-2;
      padding-top: grid.$space-2;
      gap: grid.$space-0-2;
      border-top: 1px solid color.$border-fourth;
      
      &:first-of-type {
        margin-top: grid.$space-2;
      }
      
      &:last-of-type {
        border-bottom: 1px solid color.$border-fourth;
        margin-bottom: grid.$space-2;
      }
    
      .navigation-switch {
        width: 100%;
        padding: grid.$space-1;
        position: relative;
        @include util.transition;
        border: 1px solid transparent;
        border-radius: grid.$radius-1;
        background-color: rgba(color.$bg-seventh, 0.3);
        
        > span {
          color: color.$third;
          display: flex;
          align-items: center;
          gap: grid.$space-0-2;
          font-weight: 500;
          line-height: 1.1;
          font-size: 14px;
        }
        
        svg {
          max-width: 20px;
        }
        
        &:hover {
          background-color: rgba(color.$bg-seventh, 0.8);
        }

        &.active {
          border: 1px solid color.$border-secondary;
          background-color: color.$bg-seventh;
          border-radius: grid.$radius-1;
          cursor: auto;
          pointer-events: none;
        }
      }
    }
  }
}