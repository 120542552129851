@use './color.scss';


// used for h1 - single instance for each page
@mixin page-title {
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2;
}

// used for titles and sub-titles
@mixin heading-1 {
  font-size: 1.3125rem;
  font-weight: 500;
}

@mixin heading-2 {
  font-size: 1.0625rem;
  font-weight: 600;
  color: color.$fourth;
}

@mixin heading-3 {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 28px;
}

// used for common text
@mixin text-primary {
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 28px;
}

@mixin text-secondary {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 28px;
}