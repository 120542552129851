@use './style/font.scss';
@use './style/color.scss';
@use './style/grid.scss';
@use './style/util.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700;900&display=swap');


* {
  font-family: 'Montserrat', sans-serif;
  @include font.text-primary;
}

html, body {
  scroll-behavior: smooth !important;
}

html {
  font-size: 16px;
  color: color.$primary;
}

body {
  background-color: color.$bg-third;
}

div {
  display: block;
}

svg {
  width: 100%;
  fill: color.$icon-primary;
}

.container {
  max-width: grid.$page-width;
  width: 100%;
  margin: 0 auto;
}

.page-content {
  display: flex;
  flex-direction: row-reverse;
}

main {
  min-height: calc(100vh + 1px);
  background-color: color.$bg-secondary;
  position: relative;
}

aside {
  width: grid.$sidebar-width;
  height: 100vh;
  background: color.$bg;
  color: color.$third;

  position: sticky;
  top: 0;
}

section {
  padding: grid.$section-xy;
  border-bottom: solid 10px color.$border;

  @include grid.mobile {
    padding: grid.$section-xy-mobile;
  }

  &:last-of-type {
    border-bottom: 0;
  }
}

h1 {
  @include font.page-title;
}

h2 {
  @include font.heading-1;
  color: color.$secondary;
  margin-bottom: grid.$space-4;
  font-weight: 700;
}

h3 {
  @include font.heading-2;
}

a:not(.default),
button:not(.default) {
  @include util.transition(0.1s);

  svg {
    @include util.transition(0.1s);
  }

  &:hover {
    opacity: 0.8;

    svg {
      transform: scale(0.9);
    }
  }
}

button, a {
  background-color: transparent;
  border: none; 
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.mobile {
  display: none;
}

@include grid.mobile {
  .mobile {
    display: block;
  }
}

body:has(.pdf) {
  aside {
    display: none !important;
  }
  
  .page-content {
    max-width: 100%;
  }
  
  .container {
    width: 100%;
    max-width: 100%;
  }
  
  main {
    width: 100% !important;
  }
}