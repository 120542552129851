@use 'style/font.scss';
@use 'style/grid.scss';

.textListBlock {
  :global {  
    .block + .block {
      margin-top: grid.$space-3;
      padding-top: grid.$space-2;
      border-top: 1px solid;
    }
  
    .row {
      &.mobile {
        display: none;
        margin-bottom: grid.$space-2;
    
        @include font.heading-2;
      }

      .accent {
        @include font.heading-3;
      }
    }
  }
}