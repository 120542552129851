@use 'style/util.scss';

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  @include util.transition(0.4s);
  
  &.right {
    transform: rotate(90deg);
  }
  
  &.down {
    transform: rotate(180deg);
  }
  
  &.left {
    transform: rotate(270deg);
  }
}