$sidebar-width: 280px;
$page-width: 1240px;

$section-xy: 60px 70px;
$section-xy-mobile: 50px 25px;

// margin, padding, gap
$space-0-0: 1px;
$space-0-1: 3px;
$space-0-2: 5px;
$space-1: 10px;
$space-1-1: 15px;
$space-2: 18px;
$space-3: 25px;
$space-4: 40px;
$space-5: 60px;

$radius-1: 5px;
$radius-2: 50%;
$radius-3: 10px;

@mixin mobile {
  @media (max-width: 720px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 450px) {
    @content;
  }
}
