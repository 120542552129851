@use 'style/grid.scss';
@use 'style/font.scss';

.about {
  display: flex;
  gap: grid.$space-2;

  :global {
    .about-me {
      display: none;
    }

  
    @include grid.mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .about-me {
        display: block;
      }
  
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  
    @include grid.small {
      display: flex;
      flex-direction: column;
  
      .info {
        margin-top: grid.$space-4;
  
        text-align: center;
      }
    }
  }
}